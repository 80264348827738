import React, { useState } from 'react'
import { Waypoint } from 'react-waypoint'

import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Blocks from '../components/blocks'
import WpMainMenu from '../components/wp-main-menu'
import JoinBeta from '../components/join-beta'

const IndexPage = ({ data }) => {
  const { custom_page_template, image, blocks_page } = data.allWordpressPage.edges[0].node.acf
  const [menuBg, setMenuBg] = useState(!image ? true : false)

  return (
    <>
      <WpMainMenu menuBg={menuBg} />
      {image && (
        <Waypoint
          onEnter={ () => setMenuBg(false) }
          onLeave={ () => setMenuBg(true) }
        >
          <section><Hero acf={data.allWordpressPage.edges[0].node.acf}/></section>
        </Waypoint>
      )}
      <Layout template={custom_page_template}>
        <SEO title={data.allWordpressPage.edges[0].node.title} yoastMeta={data.allWordpressPage.edges[0].node.yoast_meta} />
        {blocks_page && <Blocks blocks={blocks_page}/>}

        <JoinBeta />
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allWordpressPage(filter: {path: {eq: "/"}}) {
      edges {
        node {
          title
          template
          wordpress_id
          acf {
            __typename
            custom_page_template
            blocks_page {
              __typename
              ... on WordPressAcf_content_intro_text {
                id
                text
              }
              ... on WordPressAcf_content_text {
                id
                text
              }
              ... on WordPressAcf_content_embed {
                id
                embed
              }
              ... on WordPressAcf_content_image {
                id
                image {
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 680, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              ... on WordPressAcf_call_to_action {
                id
              }
              ... on WordPressAcf_tip {
                id
                text
                background_color
                text_color
              }
              ... on WordPressAcf_center_text {
                id
                title
                text
              }
              ... on WordPressAcf_button {
                id
                button_text
                url
                target
                position
              }
              ... on WordPressAcf_faq {
                id
                title
                text
                questions {
                  question
                  answer
                }
              }
              ... on WordPressAcf_explanatiom_slider {
                id
                full_width
                background_color
                class_names
                slides {
                  label
                  title
                  text
                  image {
                    alt_text
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 600, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                }
              }
              ... on WordPressAcf_columns_row {
                id
                full_width
                background_color
                text_color
                max_columns
                title
                columns {
                  svg_icon {
                    alt
                    filename
                    sizes {
                      large {
                        localFile {
                          url
                        }
                      }
                    }
                  }
                  image {
                    alt_text
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 600, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  title
                  text
                }
                button_text
                page {
                  url
                  target
                }
              }
              ... on WordPressAcf_feature_row {
                id
                full_width
                background_color
                text_color
                title
                columns {
                  title
                  text
                }
                image {
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 940, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                button_text
                page {
                  url
                  target
                }
              }
              ... on WordPressAcf_media {
                id
                full_width
                background_color
                text_color
                title
                text
                image {
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1220, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              ... on WordPressAcf_prices {
                id
              }
              ... on WordPressAcf_zigzag_content {
                id
                content {
                  title
                  text
                  button_text
                  page {
                    url
                    target
                  }
                  video {
                    id
                    localFile {
                      publicURL
                    }
                  }
                  image {
                    alt_text
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 600, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                }
              }
            }
            subtitle
            title
            overlay
            image {
              localFile {
                childImageSharp {
                  fixed(quality: 90, width: 1600, height: 900) {
                    src
                  }
                }
              }
            }
          }
    
          #---- YOAST QUERY ----#
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
            yoast_wpseo_canonical
            yoast_wpseo_facebook_title
            yoast_wpseo_facebook_description
            yoast_wpseo_facebook_type
            yoast_wpseo_facebook_image {
              localFile {
                childImageSharp {
                  fixed(quality: 90, width: 1200, height: 630) {
                    src
                  }
                }
              }
            }
            yoast_wpseo_twitter_title
            yoast_wpseo_twitter_description
            yoast_wpseo_twitter_image {
              localFile {
                childImageSharp {
                  fixed(quality: 90, width: 1024, height: 512) {
                    src
                  }
                }
              }
            }
            yoast_wpseo_social_url
            yoast_wpseo_company_or_person
            yoast_wpseo_person_name
            yoast_wpseo_company_name
            yoast_wpseo_company_logo
            yoast_wpseo_website_name
          }
        }
      }
    }
  }
`